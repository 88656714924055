<template>
  <div class="app-update-available-banner">
    <v-snackbar
      left
      bottom
      absolute
      multi-line
      width="31%"
      color="white"
      :timeout="-1"
      v-model="updateExists"
    >
      <template #default>
        <div class="app-update-available-banner__content pa-2 pt-3">
          <div
            class="
              secondary--font
              text-h6 text--primary
              font-weight-large
              cursor-default
            "
          >
            New version of Maropost is available
          </div>
          <div class="subtitle-1 text--secondary pb-3 cursor-default">
            Update now to get the latest version
          </div>
          <v-row class="app-update-available-banner__buttons pt-4" no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                text
                color="dark-black"
                @click="remindMeLater"
                v-track="'remind-me-later-button'"
                class="font-weight-bold mr-2"
              >
                Remind me later
              </v-btn>
              <v-btn
                outlined
                @click="updateAppVersion"
                color="dark-black"
                v-track="'update-exists-button'"
                class="font-weight-bold"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

/**
 * Shows banner to the user if an updated version of app is available
 * @author {Jatin Kamboj}
 */
export default {
  name: "AppUpdateAvailableBanner",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {};
  },
  /**
 |--------------------------------------------------
 | Computed Properties
 |--------------------------------------------------
 */
  computed: {
    ...mapGetters({
      appUpdates: "ui/appUpdates",
    }),
    updateExists: {
      get() {
        const { available, showReminder } = this.appUpdates;
        return available && showReminder;
      },
      set() {},
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setAppUpdates: "ui/setAppUpdates",
      downloadLatestVersion: "ui/downloadLatestVersion",
    }),
    remindMeLater() {
      this.setAppUpdates({
        remindLater: true,
        showReminder: false,
        reminderTime: new Date(),
      });
    },
    updateAppVersion() {
      this.downloadLatestVersion();
    },
  },
};
</script>

<style lang="scss">
.app-update-available-banner {
  .v-snack {
    z-index: 10 !important;
    .v-snack__wrapper {
      .v-snack__content {
        border-top: 5px solid var(--v-primary-base);
        border-radius: 4px;
      }
      .v-snack__action {
        display: none !important;
      }
    }
  }
}
</style>
