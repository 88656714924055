import { USER_PROPS } from "../constants/app";
import { userCollection } from "./firebase.js";
import { pick } from "@/utils";
import { $accounts } from "./apis/http/http";
import { $identity } from "@/services/apis/";
import { doc, setDoc, getDoc} from 'firebase/firestore'

/**
 * Fetches user profile details of logged in user
 */
export const getUserProfile = async (email) => {
  return await getDoc(doc(userCollection, email));
};

/**
 * Creates a user profile record in users collection
 */
export const createUserProfile = async ({ email, ...data }) => {
  const payload = pick(data, USER_PROPS);
  return await setDoc(doc(userCollection, email), payload)
};

export const userAccounts = (params = {}) => {
  return $accounts.get("accounts.json", { params });
};

export const addUser = (payload,config) => {
  return $identity.post(`session/users/add`, payload,config);
};
