import { isOnline, semverGreaterThan } from "@/utils";
import packageJson from "../../package.json";
import store from "@/store";
window.appVersion = packageJson.version;

class CacheBuster {
  constructor(checkVersion) {
    this.interval = 1 * 60 * 1000;

    this.intervalTimerId = null;
    this.isUpdateAvailable = this.isLatestVersionAvailable();

    if (checkVersion) this.checkAppVersion();
  }

  refreshCacheAndReload() {
    if (caches || window.caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    this.updateCache();
  }

  /**
   * isLatestVersionAvailable
   * @returns {Boolean} Checks whether app update is available
   */
  isLatestVersionAvailable() {
    return store?.getters["ui/appUpdates"]?.available ?? false;
  }

  // delete browser cache and hard reload
  updateCache() {
    window.location.reload(true);
  }

  /**
   * setAppVersionCheckerWorker
   * Checks for the latest build from the server every 1 minute
   */
  setAppVersionCheckerWorker() {
    if (!this.intervalTimerId) {
      this.intervalTimerId = setInterval(() => {
        this.checkAppVersion();
      }, this.interval);
    }
  }

  /**
   * showReminder
   * @description Shows app update reminder to the user
   */
  showReminder() {
    const appUpdates = store.getters["ui/appUpdates"];
    let { reminderTime, remindLater, remindLaterInterval } = appUpdates;

    if (reminderTime) {
      const showUpdateTime = new Date(
        new Date(reminderTime).setMilliseconds(remindLaterInterval)
      ).getTime();

      const currentTime = new Date().getTime();

      if (remindLater && currentTime > showUpdateTime) {
        store.dispatch("ui/setAppUpdates", { showReminder: true });
      }
    }
  }

  async checkAppVersion() {
    // Stop app checking if update already exists
    if (this.isLatestVersionAvailable()) {
      this.showReminder();
    } else if (isOnline() && typeof fetch !== "undefined") {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = window.appVersion;

          const updateAvailable = semverGreaterThan(
            latestVersion,
            currentVersion
          );

          if (updateAvailable) {
            store.dispatch("ui/setAppUpdates", {
              available: updateAvailable,
              remindLater: false,
              showReminder: updateAvailable,
            });
          }
        });
    }
  }
}

export default CacheBuster;
