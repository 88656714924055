import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "@/styles/global.scss";

import {
  isDevEnv,
  injectFilters,
  injectInjectables,
  registerDirectives,
} from "@/utils";

import "@/plugins/dayjs";

Vue.config.productionTip = false;

Vue.config.devtools = isDevEnv();

injectInjectables();

/**
 * Registers directives globally in vue
 */
registerDirectives();

injectFilters();

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
