<template>
  <div>
    <v-app>
      <v-main id="main-section">
        <v-container fluid class="py-0 px-0">
          <v-row no-gutters class="h-100vh" id="auth-container">
            <v-col
              cols="12"
              id="auth__wrapper"
              md="12"
              lg="12"
              class="d-flex align-center justify-center"
            >
              <transition name="fade" mode="out-in">
                <router-view />
              </transition>
            </v-col>
          </v-row>
        </v-container>
        <app-update-available-banner />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import AppUpdateAvailableBanner from "@/components/shared/AppUpdateAvailableBanner.vue";

/**
 * Auth layout for unauthorised users
 */
export default {
  name: "AuthLayout",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AppUpdateAvailableBanner },
};
</script>

<style lang="scss" scoped>
#main-section {
  .row {
    height: 100vh !important;
  }
}

#auth__wrapper {
  background-image: url("/img/login-bg-image.99f1b463.webp");
  background-size: cover;
}

#auth-container {
  position: relative;
  text-align: center;
  color: #fff;
}
</style>
