import appConfig from "@/config/app.config";
const { layouts } = appConfig;

/**
 * Routes meta
 */
const meta = {
  layout: layouts.app,
  requiresAuth: true,
};

/**
 * App routes
 */
const appRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"
      ),
  },
  {
    path: "/products",
    name: "products",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "products-landing-page" */ "@/views/ProductsLandingPage/index.vue"
      )
  },
  {
    path: "/security",
    name: "UserSecurityDetails",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "user-security-details" */ "@/components/widgets/UserSecurityDetails.vue"
      ),
  },

  {
    path: "/preferences",
    name: "ProfilePrefferences",
    meta,
    component: () =>
      import( 
        /* webpackChunkName: "profile-prefferences" */ "@/components/widgets/ProfilePrefferences.vue"
      ),
  },
  {
    path: "/user/new",
    name: "createProfile",
    meta: { layout: "AuthLayout" , requiresAuth: false},
    component: () =>
      import(
        /* webpackChunkName: "createProfile" */ "@/views/CreateProfile.vue"
      ),
  },
];

export default Object.freeze(appRoutes);
