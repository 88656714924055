import createMultiTabState from "vuex-multi-tab-state";

/**
 * Vuex shared tabs config
 */
const config = {
  /**
   * Key of the store in localstorage
   */
  key: "maropost-identity-shared",
  /**
   * States to be shared in all the opened tabs of the app in browser
   */
  statesPaths: ["auth.cookie"],
};

export default createMultiTabState(config);
