import Vue from "vue";
import appConfig from "@/config/app.config.js";
import navconfig from "@/config/nav.config.js";
import * as images from "@/assets/images";

import * as dictionary from "@/dictionary";
import config from "@/config/app.config";
import {
  ENVS,
  GALAXY_DOMAIN,
  RESPONSE_CODES,
  DISPLAY_BREAKPOINTS,
  MAROPOST_LOCAL_DOMAINS,
  MARKETING_CLUSTERS_LINK,
  FINDIFY_CLUSTERS_LINK
} from "./constants/app";

import * as filters from "@/filters";
const { loginRedirectUrl } = config;

import directives from "@/directives";
import dayjs from "dayjs";

/**
 * Throws an error if the required param or argument of a function is not passed
 * @param {String} paramName function parameter which is required
 * @returns {Error} error details of required param
 */
export const required = (paramName) => {
  throw new Error(`${paramName} parameter is required.`);
};

/**
 * All the injectables and services to be injected in the vue globally
 * @todo import and add all the services or the modules that needs to be injected
 * and used in the vue glovbally
 */
const injectablesObj = {
  images,
  appConfig,
  dictionary,
  navconfig
};

/**
 * Iterates over all the injectables and inject them into the vue globally
 */
export const injectInjectables = () => {
  // Iterate and injects all the injectables
  for (const key in injectablesObj) {
    Vue.prototype[`$${key}`] = injectablesObj[key];
  }
};

/**
 * Registers directives globally in the project
 */
export const registerDirectives = () => {
  for (const key in directives) {
    Vue.directive(key, directives[key]);
  }
};

export const injectFilters = () => {
  const callback = (filter) => Vue.filter(filter, filters[filter]);
  Object.keys(filters).forEach(callback);
};

/**
 * Determines if the node_env is development or not
 * @returns {Boolean} Is env development or not
 */
export const isDevEnv = () => process.env.NODE_ENV === "development";

/**
 * Checks if the object is empty or not
 * @param {Object,Array} object Object to check if it is empty or not
 * @returns {Boolean} Boolean value determining if object is empty or not
 */
export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return object && object.length === 0;
  } else if (object && isType(object, "object") && object instanceof Object) {
    return Object.keys(object).length === 0 && object.constructor === Object;
  } else {
    return true;
  }
};

/**
 * Checks if the object is of a particular data type
 * @param {*} object Object to check it's data type
 * @param {*} type Data type of the object to be expected
 * @returns Boolean Whether it of specified data type
 */
export const isType = (object, type) => typeof object === type;

/**
 * Creates a new object containing all key value pair properties present in the props array
 * @param {Object} object Object from which the properties are to be fetched
 * @param {Array} props Array of string (Object properties) name which are to be fetched from object argument and then added into new object
 * @returns {Object} New object containing properties
 */
export const pick = (object, props) => {
  let newObject = {};

  // Iterates over props array and add key value pair in newObject
  props.forEach((prop) => {
    // Checks whether property exixts in exixting object
    const hasProp = Object.hasOwnProperty.call(object, prop);
    if (hasProp) newObject[prop] = object[prop];
  });

  return newObject;
};

/**
 * Returns url name which has reffered to Identity platform
 */
export const getRefferer = () => document.referrer ?? "";

/**
 * @description fetches domain name of a url
 */
export const getUrlDomain = (url) => {
  try {
    url = new URL(url);
    return url.origin;
  } catch {
    return "";
  }
};

/**
 * Verifies that current domain is of maropost product or not
 * @returns {Boolean}
 */
export const isMaropostProduct = (url) => url.includes("maropost.com" || "findify.io");

export const redirectTo = (url) => {
  if (!url) return;
  window.location.href = url;
};

/**
 * Redirects to marketing application with jwt token
 * as query param
 * @param {String} token Jwt token of a user
 */
export const toMarketing = ({ token, jti }) => {
  if (!token || !jti) return;
  let url = getRefferer() ?? loginRedirectUrl;
  const isMaropostDomain =
    isMaropostProduct(url) || isLocalDevEnv(getUrlDomain(url));

  if (isMaropostDomain) {
    if (url.includes("app-sandbox-web"))
      url = url.replace("app-sandbox-web", "sandbox");
    if (url.includes("-web")) url = url.replace("-web", "");
    else if (url.includes("api")) url = url.replace("api", "app");
  } else url = loginRedirectUrl;

  return `${getUrlDomain(url)}/login?token=${token}&jti=${jti}`
};

// const _isD

export const toMarketingCluster = ({ token, jti }, redirect = true) => {
  const appEnv = process.env.VUE_APP_APPLICATION_ENV;
  if (!appEnv) return;

  const appUrl = MARKETING_CLUSTERS_LINK[appEnv];
  let authLink = `${appUrl}/login?token=${token}&jti=${jti}`;

  const MARKETING_ENVS = [
    "beta",
    "sandbox",
    "app-uat-octopus",
    "app-uat-rails7",
    "k8s",
    "commerce-staging"
  ];
  // If case to Manage the auth flow of sandbox enviroment
  const refferer = getRefferer() && getReffererDomain();
  if (
    refferer &&
    (MARKETING_ENVS.includes(new URL(refferer).host.split(".")[0]) ||
      isLocalDevEnv(refferer))
  ) {
    authLink = toMarketing({ token, jti });
  } if (redirect) return (window.location.href = authLink);

  return authLink;
};

/**
 * Function to check if an object contains an property
 * @param {Object} object Object in which to find a property is defined or not
 * @param {String} prop Name of the property to check its presence in the Object recieved in the param
 * @returns {Boolean} Is property present in the object or not
 */
export const hasProp = (object, prop) => {
  if (!object || !prop)
    throw new Error(
      "hasProp function has object and prop as required arguments."
    );
  return Object.prototype.hasOwnProperty.call(object, prop);
};

/**
 * Capitalizes the first character of the string
 * @param {String} str String to be capitalised
 * @returns {String} Capitalized string
 */
export const capitalize = (str) => {
  if (typeof str !== "string") return "";

  const capitalizedStr = `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  return capitalizedStr;
};

/**
 * Converts a string value into camelcase
 * @param {String} value String value to be converted to CamelCase
 * @returns {String}
 */
export const camelCase = (value) => {
  if (!value) return value;
  return `${value.slice(0, 1).toLowerCase()}${value.slice(1)}`;
};

/**
 * Determines that current view breakpoint is mobile or not
 * @returns {Boolean}
 */
export const isMobile = ({ breakpoint }) => {
  const { sm, xs } = DISPLAY_BREAKPOINTS;
  return [sm, xs].includes(breakpoint.name);
};

/**
 * Masks phone number with * and prepends last 4 charcter of mobile number
 * @returns {String} Masked mobile number
 */
export const maskPhoneNumber = (number) => {
  if (!number) return number;
  const [MASK_CHAR, PLUS] = ["*", "+"];

  const mask = new Array(number.slice(0, number.length - 4).length)
    .fill(MASK_CHAR)
    .join("");
  const prependLetter = number.includes(PLUS) ? PLUS : "";
  return `${prependLetter}${mask}${number.slice(number.length - 4)}`;
};

/**
 * defer
 * @description simply delays an action from happening
 * @param {Function} callback - the callback to execute
 * @param {String} delay - the number in milliseconds to delay callback
 */
export const defer = (callback, timeout = 500) => setTimeout(callback, timeout);

/**
 * Removes (-web or api) from maropost server url (https://cloud-web.maropost.com/)
 * @returns {String} (https://cloud.maropost.com/)
 */
export const replaceServerUrl = (url) => {
  if (!url) return url;

  if (url.includes("-web")) url = url.replace("-web", "");
  else if (url.includes("api")) url = url.replace("api", "app");

  return url;
};

/**
 * Deep freezes a object and its nested object properties
 * @param {Object} object Object to be freezed
 */
export function deepFreeze(object) {
  Object.freeze(object);

  Object.getOwnPropertyNames(object).forEach(function (prop) {
    if (
      object[prop] !== null &&
      (typeof object[prop] === "object" ||
        typeof object[prop] === "function") &&
      !Object.isFrozen(object[prop])
    ) {
      deepFreeze(object[prop]);
    }
  });

  return object;
}

/**
 * Is response code of api success
 * @returns {Boolean}
 */
export const isSuccess = (code) => RESPONSE_CODES.success === code;

/*
 * Is refferer salesforce galaxy
 */
export let isRefferrerGalaxy = () => {
  const domain = getRefferer() ? getUrlDomain(getRefferer()) : "";
  return domain === GALAXY_DOMAIN;
};

/**
 * isLocalDevEnv
 * @description is local refferer dev enviroment
 */
export const isLocalDevEnv = (refferer) => {
  return Object.values(MAROPOST_LOCAL_DOMAINS).includes(refferer);
};

// version from response - first param, local version second param
export const semverGreaterThan = (remoteVersion, projectVersion) => {
  return remoteVersion !== projectVersion;
};

export const isOnline = () => navigator.onLine;

/**
 * isValidDate
 * @description Is current date valid or not
 * @returns {Boolean}
 */
export let isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};
/**
 * Checks both arrays have same elements
 * @returns {Boolean}
 */
export const isEqual = (arr1, arr2) => {
  return arr1.every((value) => arr2.includes(value));
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

/**
 * Returns navigation object
 */
export const getNavigation = () => {
  return performance?.getEntriesByType("navigation")?.[0] ?? {};
};

/**
 * getReffererDomain
 * @returns Refferer domain
 */
export const getReffererDomain = () => getUrlDomain(getRefferer());

export function randomise(length = 10) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const isEnvTest = () => process.env.NODE_ENV === ENVS.test;

/**
 * openLinkInWindow
 * Opens a link in new tab window
 */
export const openLinkInWindow = (link, target = "_blank") => {
  window.open(link, target);
};

/*
 * decodeToken
 * @description Decodes JWT token in a Json format
 * @returns {Object}
 */
export const decodeToken = (token) => {
  if (!token) return "";

  return JSON.parse(window.atob(token?.split(".")?.[1]));
};

export const isTokenValid = (token) => {
  return token && token.split(".").length == 3;
};

/**
 * determineTokenValidity
 * @param {String} token JWT token of the user
 * @description Determines whether token is expired or not
 * @returns {Boolean}
 */
export const determineTokenValidity = async (token) => {
  return new Promise((resolve) => {
    const decodedToken = decodeToken(token);
    if (!decodedToken) return false;

    const tokenExpiry = dayjs(decodedToken.exp * 1000).utc();
    const isTokenExpired = dayjs().utc().isAfter(tokenExpiry);

    resolve(isTokenExpired);
  });
};

export const formatDate = (format, date = new Date()) => {
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) {
    console.error("formatDate invalid date parameter");
    return;
  }
  return dayjs(date).format(format).toString();
};

export const getEnv = (variable) => process.env[variable];

/**
 * queryStringToObject
 * Converts query string to object in Key value pair object
 * @returns {Object}
 */
export const queryStringToObject = (query = "") => {
  if (!query) return "";

  const queryParams = {};
  const searchParams = new URLSearchParams(query);
  searchParams.forEach((value, key) => (queryParams[key] = value));

  return queryParams;
};

export function sanitaiseInvalidRoute() {
  if (location && location.search) {
    location.replace(location.pathname + location.hash + location.search);
  }
}

/*
 redirect to findify domain if comes from findify
*/
export const toFindifyCluster = ({ token, jti }) => {
  const appEnv = process.env.VUE_APP_APPLICATION_ENV;
  if (!appEnv) return;

  const refferer = getRefferer() && getReffererDomain();

  let isValidReferrer = FINDIFY_CLUSTERS_LINK[appEnv] && FINDIFY_CLUSTERS_LINK[appEnv].includes(refferer)
  
  if (isValidReferrer) {
    let authLink = `${refferer}/login?token=${token}&jti=${jti}`;
     return (window.location.href = authLink);
  } else {
    return false
  }
};

/*
  redirect to the particular product based on refferer
*/
export const toProductCluster = (cookieToken) => {
  const refferer = getRefferer() && getReffererDomain();

  if (refferer.includes("findify")){
   return toFindifyCluster(cookieToken)
  } else {
   return toMarketingCluster(cookieToken);
  }
}
